export  function getDefaultHost() {
    //const host = process.env.NODE_ENV === 'development'?'/api':'https://admin.er18.xyz/api/admin'//测试环境
    const host = process.env.NODE_ENV === 'development'?'/api':'https://ap.er18.xyz/api/admin'//套餐测试环境
    //const host = process.env.NODE_ENV === 'development'?'/api':'https://admin.unifamily.cn/api/admin'//正式环境
    return host
}
export function getDefaultUrl(){
    //const url = 'https://admin.er18.xyz/'//测试环境
    const url = 'https://ap.er18.xyz/'//套餐测试环境
    //const url = 'https://admin.unifamily.cn/'//正式环境
    return url
}