import React, {Component} from 'react';
import {Form,notification} from 'antd';
import config from '@/commons/config-hoc';
import {FormElement, ModalContent} from "@/library/components";
import PageContent from '@/layouts/page-content';
import validator from '@/library/utils/validation-rule';


@config({
    ajax: true,
    connect: state => ({loginUser: state.system.loginUser}),
    modal: {
        title: '修改密码',
        width: 420,
    },
})
@Form.create()
export default class ModifyPassword extends Component {
    state = {
        loading: false,
    };

    handleOk = (e) => {
        e.preventDefault();

        const {loading} = this.state;
        if (loading) return;

        const {onOk, form: {validateFieldsAndScroll}} = this.props;

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values);
                this.setState({loading: true});
                this.props.ajax.post(`/UserLogin/ChangePwd`, values,{  })
                    .then((res) => {
                        if(res.Error==='原密码错误!'){
                            notification.error({
                                message: '修改密码失败',
                                description: res.Error,
                                duration: 2,
                            });
                        } else {
                            notification.success({
                                message: '修改密码成功',
                                description: res.success,
                                duration: 2,
                            });
                        }
                        if (onOk) onOk();
                    })
                    .finally(() => this.setState({loading: false}));

            }
        });
    };

    handleCancel = () => {
        const {onCancel} = this.props;
        if (onCancel) onCancel();
    };

    render() {
        const {
            loginUser,
            form,
        } = this.props;
        const id = loginUser?.id;
        const {loading} = this.state;
        const labelWidth = 100;

        return (
            <ModalContent
                loading={loading}
                surplusSpace={false}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <PageContent>
                    <Form>
                        <FormElement form={form} type="hidden" field="id" decorator={{initialValue: id}}/>
                        <FormElement
                            form={form}
                            label="原密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="oldPwd"
                            required
                            rules={[
                                validator.mobile()
                            ]}
                        />
                        <FormElement
                            form={form}
                            label="新密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="newPwd"
                            required
                            rules={[
                                validator.mobile()
                            ]}
                        />
                    </Form>
                </PageContent>
            </ModalContent>
        );
    }
}